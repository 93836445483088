import { ProForm } from "./layouts";
// 兼容代码-----------
import "antd/es/drawer/style";
import "antd/es/form/style";
import "antd/es/modal/style";
import "antd/es/rate/style";
import "antd/es/row/style";
import "antd/es/steps/style";
import "antd/es/tabs/style";
import "antd/es/upload/style";
import { GridContext } from "./helpers";
//----------------------
export { ProFormContext } from '@ant-design/pro-utils';
export * from "./components";
export { FormListContext } from "./components/List";
export { FieldContext } from "./FieldContext";
export * from "./layouts";
export { GridContext, ProForm };
export default ProForm;